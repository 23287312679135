import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            <div className="all-title-box" style={{background : "black"}}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2>ABOUT US</h2>
                    <ul className="breadcrumb" style={{ background : "black",}}>
                      <li className="breadcrumb-item"><a href="#">Home / </a></li>
                      <li className="breadcrumb-item active" style={{color :"white"}}>ABOUT US</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
<div>
{/* <div className="about-box-main"> */}
              <div className="container">
                <div className="row">
                 
                  <div className="col-lg-12">
                    {/* <center>  */}
                    <center>
                       <h1 style={{
                       fontFamily :"math",
    textTransform: "uppercase",
    fontSize: "46px",color : "rgb(5, 46, 84)"}}>About Us</h1>
    {/* fontSize: "46px",color : "rgb(5, 46, 84)"}}>WELCOME TO BATTERY SPECIALIST CAR BATTERIES AND MANY MORE.</h1> */}
                  <p style={{fontSize: "19px",
                  fontFamily :"math",
    fontWeight: "500"
   ,color  :"grey"}}>TBS, or THE BATTERY SHOP, is a brand with over 20 years of automotive battery and mechanical service experience, specialising in the supply of Car, Van, Marine, Leisure, and Solar Batteries. To ensure the highest quality, we work directly with all of the major battery manufacturers, including EXIDE, DAEWOO, AGS, OSAKA, and PHOENIX. <br /><br />
   However, in today's fast-paced world, TBS has decided to offer services online by providing the most premium branded batteries, which are available on our website, as well as other mechanical services to ensure that you can use, keep, and maintain your automobiles properly and optimally.
   Our primary goal is to provide exceptional service to all of our customers and to only sell high-quality batteries at reasonable prices. We will also keep providing the same high-quality customer service that we have for the past 20 years. <br /><br />
   While you are welcome to browse our website, if you do not find the information you seek, please contact our customer service at tbsthebatteryshop@gmail.com or WhatsApp us at +92 303 2519784, and we will do our best to help and assist you. You can also come to our store at any time and get free battery testing and fitting services at our new location on Tariq Road in Karachi.
    </p>
    <br />
                  {/* <p style={{fontSize: "19px",
                  fontFamily :"math",
    fontWeight: "500"
   ,color  :"grey"}}>We have been supplying and fitting new car batteries in the karachi area for over 15 years and have over 20 years experience within the automotive battery industry. We stock all the major brands of car battery including Exide ,Ags ,Daewoo, osaka, and Phoenix. We offer a free testing and fitting service in our new branch on Tariq road, karachi. While you are free to browse our website, if you do not find the information you are looking for, please contact our customer service department at 0303-2519784 and we will do our best to assist you.
    </p> */}
    <br /> 
                    </center>
                  
                   
                  </div>
                </div>
                <br />
               
                
              </div>
            </div>
{/* <div> */}
{/* <div> */}


            {/* End All Title Box */}
            {/* Start About Page  */}
            {/* <div className="about-box-main">
              <div className="container">
                <div className="row">
                 
                  <div className="col-lg-12">
                    <p>KM Electronics was established in 2006. We are offering a broad range of Electronic Home Appliances as well as Commercial Appliances. Our product range include all kinds of Refrigerators, Freezers, Air Conditioners, Microwave Ovens, Washing Machines and much more. We are here to facilitate you and to provide you best quality products at the lowest prices.</p>
                    <a className="btn hvr-hover" href="#" style={{background  :"black"}}>Read More</a>
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-sm-6 col-lg-4">
                    <div className="service-block-inner">
                      <h3>We are Trusted</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="service-block-inner">
                      <h3>We are Professional</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="service-block-inner">
                      <h3>We are Expert</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div> */}
            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;